import { v4 as uuidv4 } from 'uuid';

export class TimelineData {
    public Events: Array<TimelineEventData>;

    constructor() {
        this.Events = new Array<TimelineEventData>();
    }

    public loadAndAddEvents(fromJson: string): void {
        const parsedJson: any = JSON.parse(fromJson);

        for (let o of parsedJson) {
            const newEvent = new TimelineEventData();

            newEvent.EventId = o.EventId;
            newEvent.EventType = o.EventType;
            newEvent.Title = o.Title;
            newEvent.Remark = o.Remark;
            newEvent.YearOfOccurence = Number(o.YearOfOccurence);
            newEvent.TimeWindowOfOccurence = o.TimeWindowOfOccurence;
            newEvent.MonthOfOccurence = Number(o.MonthOfOccurence);
            newEvent.DayOfOccurence = Number(o.DayOfOccurence);
            
            this.Events.push(newEvent);
        }
    }

    public static createNewBirthdayEvent(birthdayYear: number, birthdayMonth: number, birthdayDay: number): TimelineEventData {
        const newEvent = new TimelineEventData();

        newEvent.EventId = uuidv4();
        newEvent.EventType = "MyBirthday"
        newEvent.Title = "My Birthday"
        newEvent.Remark = "";
        newEvent.YearOfOccurence = birthdayYear;
        newEvent.TimeWindowOfOccurence = "";
        newEvent.MonthOfOccurence = birthdayMonth;
        newEvent.DayOfOccurence = birthdayDay;
        
        return newEvent;
    }

    public addEvent(eventToAdd: TimelineEventData) {
        this.Events.push(eventToAdd);
    }

    public getStartYear(): number {
        const startTimelineItem: TimelineEventData = this.Events.reduce((p, c) => p.YearOfOccurence < c.YearOfOccurence ? p : c);
        const startTimeYear: number = startTimelineItem.YearOfOccurence;
        return startTimeYear;
    }

    public getEventsForYear(forYear: number) {
        const returnArray = this.Events.filter(e => e.YearOfOccurence == forYear);
        return returnArray;
    }

    public saveToJson(): string {
        const json = JSON.stringify(this.Events);
        return json;
    }
}

export class TimelineEventData {
    public EventId: string = "";
    public EventType: string = "";
    public Title: string = "";
    public Remark: string = "";
    public YearOfOccurence: number = 0;
    public TimeWindowOfOccurence: string = "";
    public MonthOfOccurence?: number = undefined;
    public DayOfOccurence?: number = undefined;
}