import React from "react";
import "./App.css";
import Timeline from "./Timeline";
import * as TimelineData from "./TimelineData";
import EventTimePicker from "./EventTimePicker";
import StartEventPicker from "./StartEventPicker";

import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Grid, Paper } from "@material-ui/core";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import KeyedCollection from "./helper/KeyedCollection";

import LogoLandscape from './Assets/logoLandscape.svg'

export interface IAppProps {}

export interface IAppState {
  timelineVisible: boolean;
  timelineEvents: TimelineData.TimelineData;
  newBirthdaySelected: boolean;
  newBirthdayYear: number;
  expandedAccordionIndex: number;
}

export default class App extends React.Component<IAppProps, IAppState> {
  private newTimelineBirthdayEvent?: TimelineData.TimelineEventData;
  private startEvents: KeyedCollection<TimelineData.TimelineEventData> = new KeyedCollection<TimelineData.TimelineEventData>();

  constructor(props: IAppProps) {
    super(props);
    this.state = {
      timelineVisible: false,
      timelineEvents: new TimelineData.TimelineData(),
      newBirthdaySelected: false,
      newBirthdayYear: 0,
      expandedAccordionIndex: -1,
    };
  }

  private onTimelineClose = async (): Promise<void> => {
    this.setState({
      timelineVisible: false,
    });
  };

  public render(): React.ReactElement<IAppProps> {
    return (
      <div className="App">
        {this.state.timelineVisible ? (
          <Timeline Events={this.state.timelineEvents} onTimelineClose={this.onTimelineClose}></Timeline>
        ) : (
            <React.Fragment>
              <Hidden smUp>{this.renderInitializeScreen()}</Hidden>
              <Hidden xsDown>
                <Container maxWidth="md" style={{ padding: 20 }}>
                  <Paper elevation={0} square={false} variant="outlined" style={{ padding: 40 }}>
                    {this.renderInitializeScreen()}
                  </Paper>
                </Container>
              </Hidden>
            </React.Fragment>
          )}
      </div>
    );
  }

  private handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    event.persist();
    const firstFile = (event.target as any).files[0];

    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const timelineData = new TimelineData.TimelineData();
      timelineData.loadAndAddEvents(fileReader.result as string);

      this.setState({
        timelineEvents: timelineData,
        timelineVisible: true,
      });
    };
    fileReader.readAsText(firstFile);
  };

  private renderInitializeScreen(): React.ReactElement<IAppProps> {
    return (
      <React.Fragment>
        <Container maxWidth="sm" style={{padding: "5px"}}>
          <img src={LogoLandscape} width="50%" style={{ margin: "20px" }} />
          {this.MainAccordion()}
        </Container>
      </React.Fragment>
    );
  }

  private onBirthdayPickerChange = async (
    selectedYear?: number,
    selectedTimeWindow?: string,
    selectedMonth?: number,
    selectedDay?: number
  ): Promise<void> => {
    if (!(selectedYear == undefined || selectedTimeWindow == undefined || selectedMonth == undefined || selectedDay == undefined)) {
      this.newTimelineBirthdayEvent = TimelineData.TimelineData.createNewBirthdayEvent(selectedYear || 1, selectedMonth || 1, selectedDay || 1);
      this.setState({
        newBirthdaySelected: true,
        newBirthdayYear: selectedYear,
      });
    } else {
      this.setState({
        newBirthdaySelected: false,
      });
    }
  };

  private onStartEventPickerChange = async (eventType: string, event?: TimelineData.TimelineEventData): Promise<void> => {
    if (event == undefined) {
      if (this.startEvents.ContainsKey(eventType)) this.startEvents.Remove(eventType);
    } else {
      this.startEvents.AddOrUpdate(event.EventType, event);
    }
  };

  private onStartNewTimelineButtonClick = async (): Promise<void> => {
    const timelineData = new TimelineData.TimelineData();

    if (this.newTimelineBirthdayEvent != undefined) timelineData.addEvent(this.newTimelineBirthdayEvent);

    this.startEvents.Values().forEach((element) => {
      timelineData.addEvent(element);
    });

    this.setState({
      timelineEvents: timelineData,
      timelineVisible: true,
    });
  };

  onAccordionChange = (accordionIndex: number) => () => {
    this.setState({
      expandedAccordionIndex: (this.state.expandedAccordionIndex == accordionIndex ? -1 : accordionIndex)
    });
  };

  private MainAccordion() {
    const birthdaySelectorStartYear = new Date().getFullYear() - 100;
    const birthdaySelectorStopYear = new Date().getFullYear();

    return (
      <div>
        <Accordion expanded={this.state.expandedAccordionIndex == 0 ? true : false} onChange={this.onAccordionChange(0)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="startNewTimeline-header">
            <Typography variant="h6">Start a new timeline</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box style={{ width: "100%", textAlign: "left" }}>
              <EventTimePicker
                label="My birthday:"
                displayYearStartValue={birthdaySelectorStartYear}
                displayYearStopValue={birthdaySelectorStopYear}
                onChange={this.onBirthdayPickerChange}
                requireFullDate
              ></EventTimePicker>
              <StartEventPicker
                label="My first school:"
                textfieldLabel="Name and/or town of my first school"
                eventType="School"
                startYear={this.state.newBirthdayYear}
                stopYear={birthdaySelectorStopYear}
                disabled={!this.state.newBirthdaySelected}
                onChange={this.onStartEventPickerChange}
                marginTop={45}
              ></StartEventPicker>
              <StartEventPicker
                label="My first love:"
                textfieldLabel="Name of my first love"
                eventType="PartnerComeTogether"
                startYear={this.state.newBirthdayYear}
                stopYear={birthdaySelectorStopYear}
                disabled={!this.state.newBirthdaySelected}
                onChange={this.onStartEventPickerChange}
                marginTop={35}
              ></StartEventPicker>
              <StartEventPicker
                label="My first job:"
                textfieldLabel="Name and/or place of my first job"
                eventType="Work"
                startYear={this.state.newBirthdayYear}
                stopYear={birthdaySelectorStopYear}
                disabled={!this.state.newBirthdaySelected}
                onChange={this.onStartEventPickerChange}
                marginTop={35}
              ></StartEventPicker>
              <Button variant="contained" color="primary" component="span" style={{ marginTop: 40 }} onClick={() => this.onStartNewTimelineButtonClick()}>
                Hey ho, let's go!
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.expandedAccordionIndex == 1 ? true : false} onChange={this.onAccordionChange(1)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="uploadTimeline-header">
            <Typography variant="h6">Upload a timeline</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <input accept=".json" id="upload-file-button" multiple={false} type="file" onChange={(e) => this.handleFileChange(e)} style={{ display: "none" }} />
            <label htmlFor="upload-file-button">
              <Button variant="contained" color="primary" component="span">
                Upload
              </Button>
            </label>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}