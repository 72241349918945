import React from 'react';


import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';

import TimelineEvent from './TimelineEvent';
import * as TimelineData  from './TimelineData'

export interface ITimelineYearProps {
    year: number;
    timelineData: TimelineData.TimelineData;
    onAddNewEvent: (forYear: number) => void;
    onEventChanged: (event: TimelineData.TimelineEventData) => void;
  }
  
  export interface ITimelineYearState {
  }

export default class TimelineYear extends React.Component<ITimelineYearProps, ITimelineYearState>  {

    public render(): React.ReactElement<ITimelineYearProps> {
        let eventsForThisYear = this.props.timelineData.getEventsForYear(this.props.year);
        
        const useStyles = {
            marginBottom: "20px"
        };
        
        return (
            <React.Fragment>
                <div style={useStyles}>
                    <Grid item>
                        <Typography component="div" gutterBottom >
                            <Paper elevation={1}>
                                <Typography variant="h2" component="h1" gutterBottom>
                                    {this.props.year}
                                    <IconButton 
                                        aria-label="settings"
                                        onClick={() => this.onAddEventButtonClick(this.props.year)}
                                        >
                                        <AddBoxIcon />
                                    </IconButton>  
                                </Typography>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                >
                                        {eventsForThisYear.map((e) => 
                                        <Grid key={e.EventId} item xs={12} sm={12} md={6} lg={4} xl={3}>
                                            <TimelineEvent key={e.EventId} event={e} onEventChanged={this.onEventChanged} ></TimelineEvent>
                                        </Grid>)}
                                </Grid>
                            </Paper>
                        </Typography>
                    </Grid>                        
                </div>
            </React.Fragment>
        )
    }

    private onAddEventButtonClick = async (forYear: number): Promise<void> => { 
        this.props.onAddNewEvent(forYear);
    }

    private onEventChanged = async (event: TimelineData.TimelineEventData): Promise<void> => { 
        this.props.onEventChanged(event);
    }
}