import React from 'react';

import KeyedCollection from './helper/KeyedCollection';

import CakeIcon from '@material-ui/icons/Cake';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import MemoryIcon from '@material-ui/icons/Memory';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import SportsIcon from '@material-ui/icons/Sports';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import BusinessIcon from '@material-ui/icons/Business';


export class EventTypesList {
    private eventTypesList: KeyedCollection<EventType> = new KeyedCollection<EventType>();

    constructor() {
        this.addEventType("MyBirthday", "My Birthday", <CakeIcon />, false);
        this.addEventType("Car", "Car", <DirectionsCarIcon />);
        this.addEventType("HistoricalEvent", "Historical Event", <MemoryIcon />);
        this.addEventType("Holiday", "Holiday", <WbSunnyIcon />);
        this.addEventType("HomeTown", "Home Town", <HomeIcon />);
        this.addEventType("Music", "Music", <MusicNoteIcon />);
        this.addEventType("PartnerComeTogether", "Partner Come-Togehter", <FavoriteIcon />);
        this.addEventType("Party", "Party", <SportsKabaddiIcon />);
        this.addEventType("School", "School", <SchoolIcon />);
        this.addEventType("Shopping", "Shopping", <ShoppingBasketIcon />);
        this.addEventType("SomeSpecialEvent", "Special Event", <EventAvailableIcon />);
        this.addEventType("Sport", "Sport", <SportsIcon />);
        this.addEventType("Uni", "University", <SchoolIcon />);
        this.addEventType("Work", "Work", <BusinessIcon />);
    }

    private addEventType(key: string, localeLabel: string, icon: React.ReactElement, inNewDialog = true) {
        const et = new EventType(key, localeLabel, icon, inNewDialog);
        this.eventTypesList.Add(key, et);
    }

    public getEventTypesList() {
        return this.eventTypesList;
    }
}

export class EventType {
    public key: string = "";
    public localeLabel: string = "";
    public icon?: React.ReactElement = undefined;
    public inNewDialog: boolean = true;

    constructor(key: string, localeLabel: string, icon: React.ReactElement, inNewDialog: boolean = true) {
        this.key = key;
        this.localeLabel = localeLabel;
        this.icon = icon;
        this.inNewDialog = inNewDialog;
    }
}