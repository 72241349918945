import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as EventTypesList from './TimeEventTypesList'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        margin: 15,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    cardHeaderRoot: {
      overflow: "hidden",
    },
    cardHeaderContent: {
      overflow: "hidden",
      height: '100%'
    }
  }),
);

export interface ITimeLineEventCardProps {
    title: string;
    timeLine: string;
    remark: string;
    eventType: string;
}

export default function RecipeReviewCard(props: ITimeLineEventCardProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const eventTypesList = new EventTypesList.EventTypesList().getEventTypesList();
 

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        disableTypography={true} 
        classes={{
          root: classes.cardHeaderRoot,
          content: classes.cardHeaderContent
        }}
        avatar={eventTypesList.ContainsKey(props.eventType) ? eventTypesList.Item(props.eventType).icon : null}
        action={
            <div>
                { props.remark.length > 0 ? 
                    <IconButton
                        className={clsx(classes.expand, {[classes.expandOpen]: expanded })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                : null }

                <IconButton aria-label="settings">
                    <MoreVertIcon />
                </IconButton>      
            </div>
        }
        title={
          <Typography variant="subtitle2" display="block" noWrap>{props.title}</Typography>
        }
        subheader= {
          <Typography variant="caption" display="block" noWrap>{props.timeLine}</Typography>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph align="left" >
          {props.remark}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
