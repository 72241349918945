import React from "react";
import Button from "@material-ui/core/Button";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";

export interface ConfirmationDialogRawProps {
  dialogTitle: string;
  dialogText: string;
  open: boolean;
  onClose: (value: boolean) => void;
  YesButtonCaption: string;
  NoButtonCaption: string;
}

export default function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  const [isOpen, setIsOpen] = React.useState(props.open);

  React.useEffect(() => {
    setIsOpen(props.open);
  });

  const onCancelButtonClicked = () => {
    props.onClose(false);
  };

  const onOkButtonClicked = () => {
    props.onClose(true);
  };

  return (
    <Dialog onBackdropClick={onCancelButtonClicked} onEscapeKeyDown={onCancelButtonClicked} maxWidth="xs" open={isOpen}>
      <DialogTitle id="confirmation-dialog-title">{props.dialogTitle}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="subtitle1">{props.dialogText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onOkButtonClicked} color="primary">
          {props.YesButtonCaption}
        </Button>
        <Button autoFocus onClick={onCancelButtonClicked} color="primary">
          {props.NoButtonCaption}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
