export class TimeWindowList {

    private timeWindowList: Array<TimeWindow>;

    public constructor() {
        this.timeWindowList = new Array<TimeWindow>();

        this.fillTimeWindowListForEN()
    }

    private fillTimeWindowListForEN() {
        this.timeWindowList.push(new TimeWindow("Beginning", "Beginning of Year", 1, 1));
        this.timeWindowList.push(new TimeWindow("Spring", "Spring", 3, 1));
        this.timeWindowList.push(new TimeWindow("Easter", "Easter", 4, 1));
        this.timeWindowList.push(new TimeWindow("Summer", "Summer", 6, 1));
        this.timeWindowList.push(new TimeWindow("MidOfYear", "Mid of Year", 7, 1));
        this.timeWindowList.push(new TimeWindow("Fall", "Fall", 9, 1));
        this.timeWindowList.push(new TimeWindow("XMas", "X-Mas", 12, 24));
        this.timeWindowList.push(new TimeWindow("NewYearsEve", "New Years Eve", 12, 31));
        this.timeWindowList.push(new TimeWindow("End", "End of Year", 12, 1));
    }

    public getTimeWindowList(): Array<TimeWindow> {
        return this.timeWindowList;
    }
}

export class TimeWindow {
    key: string = "";
    localeLabel: string = "";
    dateForSorting?: Date = undefined;

    constructor(key: string, localeLabel: string, monthForSorting: number, dayForSorting: number) {
        this.key = key;
        this.localeLabel = localeLabel;
        this.dateForSorting = new Date(1978, monthForSorting, dayForSorting, 17, 23, 42);
    }
}