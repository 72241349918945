import React from "react";

import TimelineYear from "./TimelineYear";
import * as TimelineData from "./TimelineData";
import YesNoDialog from "./ReusableCompontants/YesNoDialog";

import { v4 as uuidv4 } from "uuid";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";

import * as MTimeLine from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";

import * as TimeWindowList from "./TimeWindowList";
import * as EventTypesList from "./TimeEventTypesList";
import EventTimePicker from "./EventTimePicker";

import LogoLandscape from "./Assets/logoLandscape.svg";

export interface ITimelineProps {
  Events: TimelineData.TimelineData;
  onTimelineClose: () => void;
}

export interface ITimelineState {
  Events: TimelineData.TimelineData;
  EventDialogFormOpen: boolean;
  EventDialogOkButtonEnabled: boolean;
  EventDialogFormYear: number;
  EventDialogTimeWindow?: string;
  EventDialogTimeDayOfMonth?: number;
  EventDialogEventType: string;
  EventDialogEventTypeValidationError: boolean;
  EventDialogEventTypeValidationErrorText: string;
  EventDialogFormTitle: string;
  EventDialogFormTitleValidationError: boolean;
  EventDialogFormTitleValidationErrorText: string;
  EventDialogFormRemarks: string;
  timelineNavigationOpen: boolean;
  timelineNavigationStartYear: number;
  timelineCloseConfirmationDialogOpen: boolean;
}

export default class Timeline extends React.Component<ITimelineProps, ITimelineState> {
  private startYear: number;
  private endYear: number = new Date().getFullYear();

  private timeLineNavigationInterval: number = 5;

  constructor(props: ITimelineProps) {
    super(props);

    this.startYear = this.props.Events.getStartYear();

    this.state = {
      Events: this.props.Events,
      EventDialogFormOpen: false,
      EventDialogOkButtonEnabled: false,
      EventDialogFormYear: 0,
      EventDialogTimeWindow: "",
      EventDialogTimeDayOfMonth: undefined,
      EventDialogEventType: "",
      EventDialogEventTypeValidationError: false,
      EventDialogEventTypeValidationErrorText: "",
      EventDialogFormTitle: "",
      EventDialogFormTitleValidationError: false,
      EventDialogFormTitleValidationErrorText: "",
      EventDialogFormRemarks: "",
      timelineNavigationOpen: false,
      timelineNavigationStartYear: this.startYear,
      timelineCloseConfirmationDialogOpen: false,
    };
  }

  private onAddNewEvent = async (forYear: number): Promise<void> => {
    this.setState({
      EventDialogFormOpen: true,
      EventDialogOkButtonEnabled: false,
      EventDialogFormYear: forYear,
      EventDialogEventType: "",
      EventDialogEventTypeValidationError: false,
      EventDialogEventTypeValidationErrorText: "",
      EventDialogTimeWindow: "",
      EventDialogTimeDayOfMonth: undefined,
      EventDialogFormTitle: "",
      EventDialogFormTitleValidationError: false,
      EventDialogFormTitleValidationErrorText: "",
      EventDialogFormRemarks: "",
    });
  };

  private onEventChanged = async (event: TimelineData.TimelineEventData): Promise<void> => {
    const events = this.state.Events;
    this.setState({
      Events: events,
    });
  };

  private onNewEventAddedClicked = async (): Promise<void> => {
    const newEvent: TimelineData.TimelineEventData = new TimelineData.TimelineEventData();
    newEvent.EventId = uuidv4();
    newEvent.YearOfOccurence = this.state.EventDialogFormYear;

    if (this.state.EventDialogTimeWindow != undefined) {
      const timeIsMonth: boolean = this.state.EventDialogTimeWindow.startsWith("month-");
      if (timeIsMonth) {
        newEvent.MonthOfOccurence = Number(this.state.EventDialogTimeWindow.replace("month-", ""));
        newEvent.TimeWindowOfOccurence = "";
        newEvent.DayOfOccurence = this.state.EventDialogTimeDayOfMonth;
      } else if (!timeIsMonth) {
        newEvent.TimeWindowOfOccurence = this.state.EventDialogTimeWindow;
        newEvent.MonthOfOccurence = undefined;
        newEvent.DayOfOccurence = undefined;
      }
    }
    newEvent.EventType = this.state.EventDialogEventType;
    newEvent.Title = this.state.EventDialogFormTitle;
    newEvent.Remark = this.state.EventDialogFormRemarks;

    this.state.Events.addEvent(newEvent);

    this.setState({
      EventDialogFormOpen: false,
    });
  };

  private onNewEventCancelClicked = async (): Promise<void> => {
    this.setState({
      EventDialogFormOpen: false,
    });
  };

  private isEmpty(str: string): boolean {
    return !str || 0 === str.length;
  }
  private handleEventDialogFormTimeChange = async (
    selectedYear?: number,
    selectedTimeWindow?: string,
    selectedMonth?: number,
    selectedDay?: number
  ): Promise<void> => {
    this.setState({
      EventDialogTimeWindow: this.isEmpty(selectedTimeWindow ?? "") ? (selectedMonth == undefined ? "" : "month-" + selectedMonth) : selectedTimeWindow,
      EventDialogTimeDayOfMonth: selectedDay,
    });
    this.validateOkButton();
  };

  private handleEventDialogFormEventTypeChange = async (e: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
    const valueAsString: string = (e.target as any).value;

    if (valueAsString) {
      this.setState({
        EventDialogEventType: valueAsString,
        EventDialogEventTypeValidationError: false,
        EventDialogEventTypeValidationErrorText: "",
      });
    } else {
      this.setState({
        EventDialogEventType: "",
        EventDialogEventTypeValidationError: true,
        EventDialogEventTypeValidationErrorText: "required",
      });
    }

    this.validateOkButton();
  };

  private handleEventDialogFormTitleChange = async (e: React.ChangeEvent): Promise<void> => {
    const valueAsString: string = (e.target as any).value;

    if (valueAsString) {
      this.setState({
        EventDialogFormTitle: valueAsString,
        EventDialogFormTitleValidationError: false,
        EventDialogFormTitleValidationErrorText: "",
      });
    } else {
      this.setState({
        EventDialogFormTitle: "",
        EventDialogFormTitleValidationError: true,
        EventDialogFormTitleValidationErrorText: "required",
      });
    }

    this.validateOkButton();
  };

  private validateOkButton() {
    this.setState((s, p) => {
      const someValidationErrors: boolean = !s.EventDialogEventType || !s.EventDialogFormTitle;
      return { EventDialogOkButtonEnabled: !someValidationErrors };
    });
  }

  private handleEventDialogFormRemarksChange = async (e: React.ChangeEvent): Promise<void> => {
    this.setState({
      EventDialogFormRemarks: (e.target as any).value,
    });
  };

  private onShowHideTimelineNavigationClick = async (): Promise<void> => {
    this.setState({
      timelineNavigationOpen: !this.state.timelineNavigationOpen,
    });
  };

  private onTimelineNavigationDrawerClose = async (): Promise<void> => {
    this.setState({
      timelineNavigationOpen: false,
    });
  };

  private onDownloadTimelineDataClick = async (): Promise<void> => {
    const json = this.state.Events.saveToJson();

    const b = new Blob([json], { type: "application/json" });
    var element = document.createElement("a");
    document.body.appendChild(element);
    element.setAttribute("href", window.URL.createObjectURL(b));
    element.setAttribute("download", "mytimeline.json");
    element.style.display = "";

    element.click();

    document.body.removeChild(element);
  };

  private onTimelineCloseButtonClick = async (): Promise<void> => {
    this.setState({
      timelineCloseConfirmationDialogOpen: true,
    });
  };

  private onCloseTimelineConfirmationDialogClose = async (OkButtonClicked: boolean): Promise<void> => {
    if (OkButtonClicked) this.props.onTimelineClose();
    else
      this.setState({
        timelineCloseConfirmationDialogOpen: false,
      });
  };

  public render(): React.ReactElement<ITimelineProps> {
    window.scrollTo(0, 0);

    let timelineYears = [];
    for (let i: number = this.state.timelineNavigationStartYear; i <= this.state.timelineNavigationStartYear + this.timeLineNavigationInterval - 1; i++) {
      if (i <= this.endYear)
        timelineYears.push(
          <TimelineYear
            key={i}
            timelineData={this.state.Events}
            year={i}
            onAddNewEvent={this.onAddNewEvent}
            onEventChanged={this.onEventChanged}
          ></TimelineYear>
        );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar position="fixed" style={{ backgroundColor: "white" }}>
          <Toolbar>
            <Grid justify="space-between" container>
              <Hidden smUp>
                <Grid item>
                  <IconButton edge="start" color="inherit" onClick={() => this.onShowHideTimelineNavigationClick()}>
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
              <Grid item>
                <img src={LogoLandscape} height="50px" style={{ marginLeft: "20px", marginTop: "5px" }} />
              </Grid>
              <Grid item>
                <IconButton edge="end" color="inherit" onClick={() => this.onDownloadTimelineDataClick()}>
                  <SaveAltIcon />
                </IconButton>
                <IconButton color="inherit" onClick={() => this.onTimelineCloseButtonClick()}>
                  <CancelPresentationIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Hidden smUp>
          <Drawer
            open={this.state.timelineNavigationOpen}
            onClose={() => this.onTimelineNavigationDrawerClose()}
            variant="temporary"
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <div style={{ width: 250 }}>{this.renderTimelineNavigator()}</div>
          </Drawer>
        </Hidden>

        <Container maxWidth="xl" style={{ marginTop: 88, padding: "5px" }}>
          <Grid container>
            <Hidden xsDown>
              <Grid item key="TimelineNavigator" sm={5} md={3} lg={2} xl={2}>
                {this.renderTimelineNavigator()}
              </Grid>
              <Grid item key="TimeLineYears" xs={6} sm={7} md={9} lg={10} xl={10}>
                {timelineYears}
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item key="TimeLineYears" xs={12} sm={12}>
                {timelineYears}
              </Grid>
            </Hidden>
          </Grid>
        </Container>

        <Dialog open={this.state.EventDialogFormOpen} fullWidth maxWidth="lg">
          <DialogTitle id="form-dialog-title">{this.state.EventDialogFormYear + ": Add new event"}</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <EventTimePicker
                  label=""
                  year={this.state.EventDialogFormYear}
                  requireFullDate={false}
                  requireYear={false}
                  displayTimeframes
                  disabled={false}
                  onChange={this.handleEventDialogFormTimeChange}
                ></EventTimePicker>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <FormControl fullWidth>
                  <Select
                    name="EventDialogForm-EventTypeSelect"
                    native
                    value={this.state.EventDialogEventType}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => this.handleEventDialogFormEventTypeChange(e)}
                    error={this.state.EventDialogEventTypeValidationError}
                  >
                    <option value="">Type of event *</option>
                    {new EventTypesList.EventTypesList()
                      .getEventTypesList()
                      .Values()
                      .filter((e) => e.inNewDialog)
                      .map((i) => (
                        <option key={i.key} value={i.key}>
                          {i.localeLabel}
                        </option>
                      ))}
                  </Select>
                  <FormHelperText>{this.state.EventDialogEventTypeValidationErrorText}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="EventFormTitle"
                  label="Title"
                  type="text"
                  required
                  autoComplete="off"
                  fullWidth
                  margin="normal"
                  value={this.state.EventDialogFormTitle}
                  onChange={(e: React.ChangeEvent) => this.handleEventDialogFormTitleChange(e)}
                  error={this.state.EventDialogFormTitleValidationError}
                  helperText={this.state.EventDialogFormTitleValidationErrorText}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="EventFormRemark"
                  label="Remarks"
                  type="text"
                  multiline={true}
                  rows={10}
                  fullWidth
                  margin="normal"
                  value={this.state.EventDialogFormRemarks}
                  onChange={(e: React.ChangeEvent) => this.handleEventDialogFormRemarksChange(e)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button disabled={!this.state.EventDialogOkButtonEnabled} onClick={() => this.onNewEventAddedClicked()} color="primary">
              OK
            </Button>
            <Button onClick={() => this.onNewEventCancelClicked()} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <YesNoDialog
          dialogTitle="Close Timeline"
          YesButtonCaption="Close and discard timeline"
          NoButtonCaption="Cancel"
          dialogText="When closing the timeline, all changes are lost. Download timeline first, if you'd like to keep stuff."
          open={this.state.timelineCloseConfirmationDialogOpen}
          onClose={this.onCloseTimelineConfirmationDialogClose}
        />
      </React.Fragment>
    );
  }

  private renderTimelineNavigator(): React.ReactElement {
    var timeLineElements: Array<React.ReactElement> = new Array<React.ReactElement>();

    for (let i: number = this.startYear; i <= this.endYear; i += this.timeLineNavigationInterval) {
      timeLineElements.push(this.renderTimelineNavigatorItem(i, this.endYear, this.timeLineNavigationInterval));
    }

    return <MTimeLine.default align="left">{timeLineElements}</MTimeLine.default>;
  }

  private renderTimelineNavigatorItem(forYear: number, endYear: number, timeLineNavigationInterval: number): React.ReactElement {
    const age: number = forYear - this.startYear;

    return (
      <TimelineItem key={forYear}>
        {age != 0 ? (
          <TimelineOppositeContent>
            <Button onClick={() => this.handleSetTimelineNavigationStartYear(forYear)}>{age}</Button>
          </TimelineOppositeContent>
        ) : null}
        <TimelineSeparator>
          {forYear == this.state.timelineNavigationStartYear ? <TimelineDot color="primary" /> : <TimelineDot />}

          {endYear - forYear > timeLineNavigationInterval ? <TimelineConnector /> : null}
        </TimelineSeparator>
        <TimelineContent>
          <Button color="primary" onClick={() => this.handleSetTimelineNavigationStartYear(forYear)}>
            {forYear}
          </Button>
        </TimelineContent>
      </TimelineItem>
    );
  }

  private handleSetTimelineNavigationStartYear = async (startYear: number): Promise<void> => {
    this.setState({
      timelineNavigationOpen: false,
      timelineNavigationStartYear: startYear,
    });
  };
}
