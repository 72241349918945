import React from "react";

import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import * as TimeWindowList from "./TimeWindowList";

export interface IEventTimePickerProps {
  label: string;
  year?: number;
  displayYearStartValue?: number;
  displayYearStopValue?: number;
  displayTimeframes?: boolean;
  onChange: (selectedYear?: number, selectedTimeWindow?: string, selectedMonth?: number, selectedDay?: number) => void;
  requireFullDate?: boolean;
  requireYear?: boolean;
  disabled?: boolean;
  marginTop?: number;
}

export interface IEventTimePickerState {
  yearPickerVisible: boolean;
  displayYearStartValue?: number;
  selectedYear: string;
  selectedTimeWindow: string;
  selectedTimeWindowControlItem: string;
  selectedMonthNumber: string;
  selectedDayOfMonth: string;
  timeWindowEnabled: boolean;
  dayOfMonthEnabled: boolean;
  dayOfMonthDayList: Array<string>;
  isRequiredYearValidationError: boolean;
  isRequiredTimeFrameValidationError: boolean;
  isRequiredDayValidationError: boolean;
  disabled: boolean;
}

export default class EventTimePicker extends React.Component<IEventTimePickerProps, IEventTimePickerState> {
  constructor(props: IEventTimePickerProps) {
    super(props);

    this.state = {
      yearPickerVisible: this.props.displayYearStartValue != undefined && this.props.displayYearStopValue != undefined,
      displayYearStartValue: this.props.displayYearStartValue,
      selectedYear: this.props.year ? this.props.year.toString() : "",
      selectedTimeWindow: "",
      selectedTimeWindowControlItem: "",
      selectedMonthNumber: "",
      selectedDayOfMonth: "",
      timeWindowEnabled: this.props.requireYear || this.props.requireFullDate ? false : true,
      dayOfMonthEnabled: false,
      isRequiredYearValidationError: this.props.disabled ? false : (this.props.requireYear ?? false) || (this.props.requireFullDate ?? false),
      isRequiredTimeFrameValidationError: this.props.disabled ? false : this.props.requireFullDate ?? false,
      isRequiredDayValidationError: this.props.disabled ? false : this.props.requireFullDate ?? false,
      dayOfMonthDayList: new Array<string>(),
      disabled: this.props.disabled ?? false,
    };
  }

  public componentDidUpdate(prevProps: IEventTimePickerProps): void {
    if (prevProps.disabled != this.props.disabled) this.setState({ disabled: this.props.disabled ?? false });

    if (prevProps.displayYearStartValue != this.props.displayYearStartValue) this.setState({ displayYearStartValue: this.props.displayYearStartValue });
  }

  public render(): React.ReactElement<IEventTimePickerProps> {
    const timeWindowSelectWidth = this.state.yearPickerVisible ? 7 : 10;

    return (
      <React.Fragment>
        <div style={{ marginTop: this.props.marginTop ?? 0 }}>
          <Typography variant="subtitle2" gutterBottom>
            {this.props.label}
          </Typography>
          <Grid container>
            {this.state.yearPickerVisible ? (
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <Select
                    name="YearSelect"
                    native
                    required
                    autoFocus={this.state.yearPickerVisible}
                    value={this.state.selectedYear}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => this.handleSelectedYearChange(e)}
                    style={{ marginRight: 20 }}
                    error={this.state.isRequiredYearValidationError}
                    disabled={this.state.disabled}
                  >
                    <option key="yearItem" value="" disabled={this.props.requireYear || this.props.requireFullDate}>
                      {this.props.requireYear || this.props.requireFullDate ? "year*" : "year"}
                    </option>
                    {this.renderYearSelectOptions()}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            <Grid item xs={timeWindowSelectWidth}>
              <FormControl fullWidth>
                <Select
                  name="TimeWindowSelect"
                  native
                  required
                  disabled={this.state.disabled || !this.state.timeWindowEnabled}
                  autoFocus={!this.state.yearPickerVisible}
                  value={this.state.selectedTimeWindowControlItem}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => this.handleEventDialogFormTimeChange(e)}
                  style={{ marginRight: 20 }}
                  error={this.state.isRequiredTimeFrameValidationError}
                >
                  {this.props.displayTimeframes ? (
                    <React.Fragment>
                      <option key="timeFrameItem" value="" disabled={this.props.requireFullDate}>
                        {this.props.requireFullDate ? "more specific time*" : "more specific time"}
                      </option>
                      <optgroup label="Month">{this.renderMonthNameSelectOptions()}</optgroup>
                      <optgroup label="Timeframe">{this.renderTimeWindowSelectOptions()}</optgroup>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <option key="monthItem" value="" disabled={this.props.requireFullDate}>
                        {this.props.requireFullDate ? "month*" : "month"}
                      </option>
                      {this.renderMonthNameSelectOptions()}
                    </React.Fragment>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <Select
                  name="dayOfMonthSelect"
                  native
                  disabled={this.state.disabled || !this.state.dayOfMonthEnabled}
                  value={this.state.selectedDayOfMonth}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => this.handleEventDialogFormTimeDayOfMonthChange(e)}
                  error={this.state.isRequiredDayValidationError}
                >
                  <option key="dayItem" value="" disabled={this.props.requireFullDate}>
                    {this.props.requireFullDate ? "day*" : "day"}
                  </option>
                  {this.state.dayOfMonthDayList.map((i) => (
                    <option key={"dayOfMonthItem-" + i.toString()} value={i}>
                      {i}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }

  private renderYearSelectOptions(): Array<React.ReactFragment> {
    const returnElements: Array<React.ReactFragment> = [];

    for (let i: number = this.state.displayYearStartValue || 0; i < (this.props.displayYearStopValue || 0); i++) {
      const fragment = (
        <option key={i.toString()} value={i.toString()}>
          {i.toString()}
        </option>
      );
      returnElements.push(fragment);
    }

    return returnElements;
  }

  private renderMonthNameSelectOptions(): Array<React.ReactFragment> {
    const returnElements: Array<React.ReactFragment> = [];

    for (let i: number = 0; i < 12; i++) {
      const date = new Date(1978, i, 1, 17, 23, 42);
      const monthName = date.toLocaleString("default", { month: "long" });
      const monthValue = "month-" + (i + 1);
      const fragment = (
        <option key={monthValue} value={monthValue}>
          {monthName}
        </option>
      );
      returnElements.push(fragment);
    }

    return returnElements;
  }

  private renderTimeWindowSelectOptions(): Array<React.ReactFragment> {
    const returnElements: Array<React.ReactFragment> = [];

    const tw = new TimeWindowList.TimeWindowList();
    const tws = tw.getTimeWindowList();

    tws.map((l) =>
      returnElements.push(
        <option key={l.key} value={l.key}>
          {l.localeLabel}
        </option>
      )
    );

    return returnElements;
  }

  private handleSelectedYearChange = async (e: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
    const selectedYearAsString: string = (e.target as any).value;
    const selectedYearAsNumber: number = Number(selectedYearAsString);

    let dayOfMonthDayList: Array<string> = new Array<string>();
    if (!this.isEmpty(selectedYearAsString) && !isNaN(selectedYearAsNumber) && !isNaN(Number(this.state.selectedMonthNumber))) {
      dayOfMonthDayList = this.getDaysOfMonthList(Number(selectedYearAsNumber), Number(this.state.selectedMonthNumber));
    }

    this.setState({
      selectedYear: selectedYearAsString,
      dayOfMonthDayList: dayOfMonthDayList,
      timeWindowEnabled: dayOfMonthDayList.length > 0,
    });

    this.passSelection();
  };

  private handleEventDialogFormTimeChange = async (e: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
    const valueAsString: string = (e.target as any).value;

    if (!valueAsString) {
      this.setState({
        selectedTimeWindow: valueAsString,
        selectedTimeWindowControlItem: valueAsString,
        selectedMonthNumber: "",
        selectedDayOfMonth: "",
        dayOfMonthEnabled: false,
        dayOfMonthDayList: [],
      });
    } else {
      const dayOfMonthEnabled: boolean = valueAsString.startsWith("month-");
      const monthNumber = valueAsString.replace("month-", "");
      let dayOfMOnthDayList: Array<string> = new Array<string>();

      if (dayOfMonthEnabled) dayOfMOnthDayList = this.getDaysOfMonthList(Number(this.state.selectedYear), Number(monthNumber));

      this.setState({
        selectedTimeWindow: !dayOfMonthEnabled ? valueAsString : "",
        selectedTimeWindowControlItem: valueAsString,
        dayOfMonthEnabled: dayOfMonthEnabled,
        selectedMonthNumber: monthNumber,
        selectedDayOfMonth: "",
        dayOfMonthDayList: dayOfMOnthDayList,
      });
    }
    this.passSelection();
  };

  private getDaysOfMonthList(forYear: number, forMonth: number): Array<string> {
    const dayOfMOnthDayList: Array<string> = new Array<string>();

    const lastDayOfMonth = new Date(forYear, forMonth, 0, 17, 23, 42).getDate();
    for (let d: number = 1; d <= lastDayOfMonth; d++) {
      dayOfMOnthDayList.push(
        d.toLocaleString(undefined, {
          useGrouping: false,
          minimumIntegerDigits: 2,
        })
      );
    }

    return dayOfMOnthDayList;
  }

  private handleEventDialogFormTimeDayOfMonthChange = async (e: React.ChangeEvent<{ value: unknown }>): Promise<void> => {
    const selectedDay = (e.target as any).value;
    const selectedDayAsString = selectedDay as string;

    this.setState({
      selectedDayOfMonth: selectedDayAsString,
    });
    this.passSelection();
  };

  private isEmpty(str: string): boolean {
    return !str || 0 === str.length;
  }

  private passSelection() {
    this.setState((s, p) => {
      const selectedYearValid = (!p.requireYear ?? false) || ((p.requireFullDate ?? false) && !this.isEmpty(s.selectedYear) && !isNaN(Number(s.selectedYear)));
      const selectedMonthValid =
        (!p.requireFullDate ?? false) || ((p.requireFullDate ?? false) && !this.isEmpty(s.selectedMonthNumber) && !isNaN(Number(s.selectedMonthNumber)));
      const selectedTimewindowValid = (!p.requireFullDate ?? false) || ((p.requireFullDate ?? false) && !this.isEmpty(s.selectedTimeWindow));
      const selectedDayValid =
        (!p.requireFullDate ?? false) || ((p.requireFullDate ?? false) && !this.isEmpty(s.selectedDayOfMonth) && !isNaN(Number(s.selectedDayOfMonth)));

      if (
        (((p.requireYear ?? false) && selectedYearValid) || !p.requireYear) &&
        (!p.requireFullDate || ((p.requireFullDate ?? false) && (selectedMonthValid || selectedTimewindowValid) && selectedDayValid))
      )
        this.props.onChange(Number(s.selectedYear), s.selectedTimeWindow, Number(s.selectedMonthNumber), Number(s.selectedDayOfMonth));
      else this.props.onChange(undefined, undefined, undefined, undefined);

      return {
        isRequiredYearValidationError: s.disabled ? false : !selectedYearValid,
        isRequiredTimeFrameValidationError: s.disabled ? false : !(selectedMonthValid || selectedTimewindowValid),
        isRequiredDayValidationError: s.disabled ? false : !selectedDayValid,
      };
    });
  }
}
