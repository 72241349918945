import React from 'react';
import * as TimelineData  from './TimelineData'
import TimelineEventCard from './TimeLineEventCard'

export interface ITimelineEventProps {
    event: TimelineData.TimelineEventData
    onEventChanged: (event: TimelineData.TimelineEventData) => void;
  }
  
  export interface ITimelineEventState {
  }

export default class TimelineEvent extends React.Component<ITimelineEventProps, ITimelineEventProps>  {
    public render(): React.ReactElement<ITimelineEventProps> {
        return (
            <React.Fragment>
                <TimelineEventCard 
                    title={this.props.event.Title}
                    timeLine={this.formatTimeline(this.props.event)} 
                    eventType={this.props.event.EventType}
                    remark={this.props.event.Remark} 
                />
            </React.Fragment>
        )
    }

    private onEditEventButtonClick = async (): Promise<void> => {
        //<button onClick={() => this.onEditEventButtonClick()}>edit event</button>
        this.props.event.Title = "changed title: was: " + this.props.event.Title;
        this.props.onEventChanged(this.props.event);
    }

    private formatTimeline(event: TimelineData.TimelineEventData): string {

        if (event.TimeWindowOfOccurence)
            return event.TimeWindowOfOccurence;

        if (event.MonthOfOccurence && event.DayOfOccurence)
            return event.DayOfOccurence + ". " + this.formatMonth(event.MonthOfOccurence);

        if (event.MonthOfOccurence)
        {
            return this.formatMonth(event.MonthOfOccurence);
        }
            
        return "";
    }

    private formatMonth(monthNumber: number): string {
        const date: Date = new Date(1978, monthNumber - 1, 1, 17, 23, 42);
        return date.toLocaleString('default', { month: 'long' });
    }
}