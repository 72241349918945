import React from "react";

import { IEventTimePickerProps } from "./EventTimePicker";
import * as TimelineData from "./TimelineData";
import TextField from "@material-ui/core/TextField";
import EventTimePicker from "./EventTimePicker";
import { v4 as uuidv4 } from 'uuid';

export interface IStartEventPickerProps {
  label: string;
  textfieldLabel: string;
  eventType: string;
  startYear: number;
  stopYear: number;
  onChange: (eventType: string, timelineEventData?: TimelineData.TimelineEventData) => void;
  disabled?: boolean;
  marginTop?: number;
}

export interface IStartEventPickerState {
  startYear: number;
  disabled?: boolean;
  selectedYear?: number;
  selectedTimeWindow?: string;
  selectedMonth?: number;
  selectedDay?: number;
  textfieldRequiredAndEnabled: boolean;
  textfieldValue: string;
  textfieldValidationError: boolean;
}

export default class StartEventPicker extends React.Component<IStartEventPickerProps, IStartEventPickerState> {
  constructor(props: IStartEventPickerProps) {
    super(props);

    this.state = {
      startYear: this.props.startYear,
      disabled: this.props.disabled ?? false,
      textfieldRequiredAndEnabled: false,
      textfieldValidationError: false,
      textfieldValue: "",
    };
  }

  public componentDidUpdate(prevProps: IStartEventPickerProps): void {
    if (prevProps.disabled != this.props.disabled) this.setState({ disabled: this.props.disabled });
    if (prevProps.startYear != this.props.startYear) this.setState({ startYear: this.props.startYear });
  }

  private onEventTimePickerChange = async (
    selectedYear?: number,
    selectedTimeWindow?: string,
    selectedMonth?: number,
    selectedDay?: number
  ): Promise<void> => {
    this.setState({
      selectedYear: selectedYear,
      selectedTimeWindow: selectedTimeWindow,
      selectedMonth: selectedMonth,
      selectedDay: selectedDay,
      textfieldRequiredAndEnabled: (selectedYear ?? 0) != 0,
      textfieldValidationError: (selectedYear ?? 0) != 0 && this.isEmpty(this.state.textfieldValue),
    });

    this.onValidate();
  };

  private onTextfieldChange = async (e: React.ChangeEvent): Promise<void> => {
    const valueAsString: string = (e.target as any).value as string;

    this.setState({
      textfieldValue: valueAsString,
      textfieldValidationError: this.isEmpty(valueAsString),
    });

    this.onValidate();
  };

  private onValidate() {

    this.setState((s, p) => {
      if ((s.selectedYear ?? 0) > 0 && !s.textfieldValidationError) {
        let newEvent: TimelineData.TimelineEventData = new TimelineData.TimelineEventData();
        newEvent.EventId = uuidv4();
        newEvent.EventType = p.eventType;
        newEvent.Title = s.textfieldValue;
        newEvent.Remark = "";
        newEvent.YearOfOccurence = s.selectedYear ?? 0;
        newEvent.TimeWindowOfOccurence = s.selectedTimeWindow ?? "";
        newEvent.MonthOfOccurence = s.selectedMonth;
        newEvent.DayOfOccurence = s.selectedDay;
        p.onChange(p.eventType, newEvent);
      }
      else {
        p.onChange(p.eventType, undefined);
      }
    });
  }

  public render(): React.ReactElement<IEventTimePickerProps> {
    return (
      <React.Fragment>
        <EventTimePicker
          label={this.props.label}
          displayYearStartValue={this.props.startYear}
          displayYearStopValue={this.props.stopYear}
          displayTimeframes
          onChange={this.onEventTimePickerChange}
          disabled={this.props.disabled}
          marginTop={this.props.marginTop ?? 10}
        ></EventTimePicker>
        <TextField
          id="EventFormTitle"
          label={this.props.textfieldLabel}
          type="text"
          required={this.state.textfieldRequiredAndEnabled}
          autoComplete="off"
          fullWidth
          margin="normal"
          disabled={this.props.disabled || !this.state.textfieldRequiredAndEnabled}
          error={this.state.textfieldValidationError}
          value={this.state.textfieldValue}
          onChange={(e: React.ChangeEvent) => this.onTextfieldChange(e)}
        />
      </React.Fragment>
    );
  }

  private isEmpty(str: string): boolean {
    return !str || 0 === str.length;
  }
}